import { ChatOpenAI } from '@langchain/openai'
import { ChatAnthropic } from '@langchain/anthropic'
import { ChatGoogleGenerativeAI } from '@langchain/google-genai'
import { ChatMistralAI } from '@langchain/mistralai'
import { ChatGroq } from "@langchain/groq";
import { HumanMessage, SystemMessage } from '@langchain/core/messages'
import axios from 'axios';

// Environment variables for subscribed users
const SUBSCRIBED_API_KEYS = {
  openai: process.env.REACT_APP_OPENAI_API_KEY,
  claude: process.env.REACT_APP_ANTHROPIC_API_KEY,
  gemini: process.env.REACT_APP_GEMINI_API_KEY,
  mistral: process.env.REACT_APP_MISTRAL_API_KEY,
  grok: process.env.REACT_APP_GROK_API_KEY,
  groq: process.env.REACT_APP_GROQ_API_KEY,
  openrouter: process.env.REACT_APP_OPENROUTER_API_KEY
}

// Helper function to check if we're rate limited
const isOpenRouterRateLimited = () => {
  const rateLimitTimestamp = localStorage.getItem('openrouter_rate_limit');
  if (!rateLimitTimestamp) return false;
  
  // Check if an hour has passed since the rate limit
  const oneHour = 60 * 60 * 1000; // milliseconds
  const hasExpired = Date.now() - parseInt(rateLimitTimestamp) > oneHour;
  
  if (hasExpired) {
    localStorage.removeItem('openrouter_rate_limit');
    return false;
  }
  
  return true;
};

// Function to fetch OpenRouter models dynamically
export const getOpenRouterModels = async () => {
  if (isOpenRouterRateLimited()) {
    console.error('OpenRouter API rate limit exceeded. Please try again later.');
    return { models: [], isRateLimited: true };
  }

  const apiKey = SUBSCRIBED_API_KEYS.openrouter;
  if (!apiKey) {
    console.error('OpenRouter API key not found');
    return { models: [], isRateLimited: false };
  }

  try {
    const response = await axios.get('https://openrouter.ai/api/v1/models', {
      headers: {
        'HTTP-Referer': window.location.href,
        'X-Title': 'SoloRecall',
        'Authorization': `Bearer ${apiKey}`
      }
    });

    if (response.data && Array.isArray(response.data.data)) {
      const models = response.data.data.map(model => ({
        value: model.id,
        label: model.name?.split(' - ')[0] || model.label
      }));
      return { models, isRateLimited: false };
    }
    console.error('Invalid response format from OpenRouter API:', response.data);
    return { models: [], isRateLimited: false };
  } catch (error) {
    if (error.response?.status === 429) {
      console.error('OpenRouter API rate limit exceeded. Please try again later.');
      localStorage.setItem('openrouter_rate_limit', Date.now());
      return { models: [], isRateLimited: true };
    }
    console.error('Error fetching OpenRouter models:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
    }
    return { models: [], isRateLimited: false };
  }
};

// Function to fetch OpenAI models dynamically
export const getOpenAIModels = async () => {
  const apiKey = SUBSCRIBED_API_KEYS.openai;
  if (!apiKey) {
    console.error('OpenAI API key not found');
    return { models: [], error: 'API key not found' };
  }

  try {
    const response = await axios.get('https://api.openai.com/v1/models', {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
        'Content-Type': 'application/json'
      }
    });

    // Filter for chat models and format them
    const chatModels = response.data.data
      .filter(model => model.id.includes('gpt'))
      .map(model => ({
        value: model.id,
        label: model.id.split('/').pop()
      }));

    return { models: chatModels, error: null };
  } catch (error) {
    console.error('Error fetching OpenAI models:', error);
    return { models: [], error: error.message };
  }
};

// Base LLM class to standardize interactions
class BaseLLM {
  constructor (config = {}) {
    this.config = {
      temperature: 0.7,
      streaming: true,
      ...config
    }
  }

  async generateResponse (prompt, options = {}) {
    throw new Error('Generate method must be implemented')
  }
}

// OpenAI Implementation
class OpenAIChat extends BaseLLM {
  constructor (apiKey, config = {}, isSubscribed = false) {
    console.log("Open AI model ", config)
    super(config)
    this.model = new ChatOpenAI({
      openAIApiKey: isSubscribed ? SUBSCRIBED_API_KEYS.openai : apiKey,
      modelName: config.model || 'gpt-4',
      ...this.config
    })
  }

  async generateResponse (prompt, options = {}) {
    const messages = [
      new SystemMessage('You are a helpful AI assistant.'),
      new HumanMessage(prompt)
    ]
    return await this.model.invoke(messages)
  }
}

// Anthropic Implementation
class ClaudeChat extends BaseLLM {
  constructor (apiKey, config = {}, isSubscribed = false) {
    super(config)
    this.model = new ChatAnthropic({
      anthropicApiKey: isSubscribed ? SUBSCRIBED_API_KEYS.claude : apiKey,
      model: config.model || 'claude-3-haiku-20240307',
      clientOptions: {
        defaultHeaders: {
          "X-Api-Key": SUBSCRIBED_API_KEYS.claude,
          "anthropic-version": "2023-06-01",
          "content-type": "application/json",
          "anthropic-dangerous-direct-browser-access": "true",
        },
      },
      max_tokens: 1024,
      ...this.config
    })
  }

  async generateResponse (prompt, options = {}) {
    const messages = [
      new SystemMessage('You are a helpful AI assistant.'),
      new HumanMessage(prompt)
    ]
    return await this.model.invoke(messages)
  }
}

// Google Implementation
class GeminiChat extends BaseLLM {
  constructor (apiKey, config = {}, isSubscribed = false) {
    super(config)
    this.model = new ChatGoogleGenerativeAI({
      apiKey: isSubscribed ? SUBSCRIBED_API_KEYS.gemini : apiKey,
      modelName: config.model || 'gemini-1.5-pro', // Default model if not specified
      ...this.config
    })
  }

  async generateResponse (prompt, options = {}) {
    const messages = [
      new SystemMessage('You are a helpful AI assistant.'),
      new HumanMessage(prompt)
    ]
    return await this.model.invoke(messages)
  }
}

// Mistral Implementation using LangChain's ChatMistralAI
class MistralChat extends BaseLLM {
  constructor (apiKey, config = {}, isSubscribed = false) {
    super(config)
    this.model = new ChatMistralAI({
      apiKey: isSubscribed ? process.env.REACT_APP_MISTRAL_API_KEY : apiKey,
      modelName: config.model || 'mistral-small-latest',
      ...this.config
    })
  }

  async generateResponse (prompt, options = {}) {
    console.log('prompt', prompt)
    try {
      const messages = [
        new SystemMessage('You are a helpful AI assistant.'),
        new HumanMessage(prompt)
      ]
      return await this.model.invoke(messages)
    } catch (error) {
      console.error('Mistral API Error:', {
        error,
        modelName: this.model.modelName,
        config: this.config
      })
      throw error
    }
  }
}

// OpenRouter Implementation
class OpenRouterChat extends BaseLLM {
  constructor (apiKey, config = {}, isSubscribed = false) {
    console.log("Open Router Model ", config)
    super(config)
    this.model = new ChatOpenAI({
      openAIApiKey: isSubscribed ? SUBSCRIBED_API_KEYS.openrouter : apiKey,
      modelName: config.model || 'anthropic/claude-3.5-sonnet',
      basePath: 'https://api.openrouter.ai/v1',  // replace with OpenRouter’s endpoint if required
      ...this.config
    }, {
      baseURL: 'https://openrouter.ai/api/v1'
    }
  )
  }

  async generateResponse (prompt, options = {}) {
    const messages = [
      new SystemMessage('You are a helpful AI assistant.'),
      new HumanMessage(prompt)
    ]
    return await this.model.invoke(messages)
  }
}

// Groq Implementation
class GroqChat extends BaseLLM {
  constructor (apiKey, config = {}, isSubscribed = false) {
    super(config)
    this.model = new ChatGroq({
      apiKey: isSubscribed ? SUBSCRIBED_API_KEYS.groq : apiKey,
      // anthropicApiKey: isSubscribed ? process.env.REACT_APP_GROQ_API_KEY : apiKey,
      modelName: config.model || 'mixtral-8x7b-32768',
      ...this.config
    })
  }

  async generateResponse (prompt, options = {}) {
    const messages = [
      new SystemMessage('You are a helpful AI assistant.'),
      new HumanMessage(prompt)
    ]
    return await this.model.invoke(messages)
  }
}

// LLM Factory
export const createLLM = (
  provider,
  apiKey,
  config = {},
  isSubscribed = false
) => {
  switch (provider.toLowerCase()) {
    case 'openai':
      return new OpenAIChat(apiKey, config, isSubscribed)
    case 'claude':
      return new ClaudeChat(apiKey, config, isSubscribed)
    case 'gemini':
      return new GeminiChat(apiKey, config, isSubscribed)
    case 'mistral':
      return new MistralChat(apiKey, config, isSubscribed)
    case 'openrouter':
      return new OpenRouterChat(apiKey, config, isSubscribed)
    case 'groq':
      return new GroqChat(apiKey, config, isSubscribed)
    default:
      throw new Error(`Unsupported provider: ${provider}`)
  }
}


// Main chat function
export const chat = async (
  prompt,
  provider,
  model,
  apiKey,
  isSubscribed = false
) => {
  try {
    if (!isSubscribed && !apiKey) {
      throw new Error(
        `Please provide an API key for ${provider} in settings or subscribe to a plan`
      )
    }
    if (isSubscribed && !SUBSCRIBED_API_KEYS[provider]) {
      throw new Error(
        `Environment variable for ${provider} is not set. Please contact support.`
      )
    }
    console.log('OOOOOOOOOOOOOOOOPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP', apiKey)
    const llm = createLLM(provider, apiKey, { model }, isSubscribed)
    const response = await llm.generateResponse(prompt)
    return response.content
  } catch (error) {
    console.error('LLM Error:', error)
    throw new Error(`${provider} Error: ${error.message}`)
  }
}

// Stream chat function
export const streamChat = async function* (prompt, provider, model, apiKey, isSubscribed = false) {
  try {
    if (!isSubscribed && !apiKey) {
      throw new Error(
        `Please provide an API key for ${provider} in settings or subscribe to a plan`
      )
    }

    const llm = createLLM(
      provider,
      apiKey,
      {
        model,
        streaming: true
      },
      isSubscribed
    )

    const stream = await llm.generateResponse(prompt, { streaming: true })

    for await (const chunk of stream) {
      yield chunk.content
    }
  } catch (error) {
    console.error('Stream Error:', error)
    throw new Error(`${provider} Streaming Error: ${error.message}`)
  }
}

export const getAvailableModels = async (provider, apiKey, isSubscribed) => {
  try {
    switch (provider.toLowerCase()) {
      case 'openai':
        return await getOpenAIModels();
      case 'openrouter':
        return await getOpenRouterModels();
      case 'gemini':
        // Fetch Gemini models from API
        const response = await axios.get(`https://generativelanguage.googleapis.com/v1/models`, {
          params: {
            key: process.env.REACT_APP_GEMINI_API_KEY
          },
          headers: {
            'Content-Type': 'application/json'
          }
        });
        return response.data.models.map(model => ({ value: model.name, label: model.displayName }));
      default:
        return []; // Return empty array for unsupported providers
    }
  } catch (error) {
    console.error(`Error fetching models for ${provider}:`, error);
    return [];
  }
};
