import axios from 'axios';

class TTSService {
  constructor() {
    this.currentProvider = 'elevenlabs'; // Set ElevenLabs as default
    this.audioContext = null;
    this.audioSource = null;
    this.isPaused = false;
    
    this.providers = {
      elevenlabs: {
        voices: {},
        audioElement: new Audio()
      }
    };
  }

  async initialize() {
    try {
      await this.initializeElevenLabs();
    } catch (error) {
      console.error('Error initializing TTS service:', error);
    }
  }

  async initializeElevenLabs() {
    const apiKey = localStorage.getItem('apiKeys')
      ? JSON.parse(localStorage.getItem('apiKeys')).elevenlabs
      : null;

    if (!apiKey) {
      console.warn('ElevenLabs API key not found. Voice features will be disabled.');
      return;
    }

    try {
      const response = await axios.get('https://api.elevenlabs.io/v1/voices', {
        headers: {
          'xi-api-key': apiKey
        }
      });

      this.providers.elevenlabs.voices = response.data.voices.reduce((acc, voice) => {
        acc[voice.id] = voice;
        return acc;
      }, {});
    } catch (error) {
      console.error('Error fetching ElevenLabs voices:', error);
      throw new Error('Failed to fetch ElevenLabs voices. Please check your API key.');
    }
  }

  async convertText(text, voiceId, apiKey) {
    if (!text || !voiceId || !apiKey) return;
    
    try {
      return await this.convertWithElevenLabs(text, voiceId, apiKey);
    } catch (error) {
      console.error('TTS conversion error:', error);
      throw error;
    }
  }

  async convertWithElevenLabs(text, voiceId, apiKey) {
    const response = await axios.post(
      'https://api.elevenlabs.io/v1/text-to-speech/v1',
      {
        text,
        voice_settings: {
          stability: 0.5,
          similarity_boost: 0.5,
          model_id: voiceId
        }
      },
      {
        headers: {
          'xi-api-key': apiKey,
          'Content-Type': 'application/json'
        },
        responseType: 'arraybuffer'
      }
    );

    const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
    const audioUrl = URL.createObjectURL(audioBlob);
    this.providers.elevenlabs.audioElement.src = audioUrl;
    return this.providers.elevenlabs.audioElement;
  }

  async refreshVoices() {
    await this.initializeElevenLabs();
    return this.providers.elevenlabs.voices;
  }

  getAvailableVoices(provider) {
    return Object.values(this.providers[provider].voices);
  }

  getAvailableProviders() {
    return Object.keys(this.providers).map(provider => ({
      value: provider,
      label: provider
    }));
  }

  play() {
    this.providers.elevenlabs.audioElement.play();
  }

  pause() {
    this.providers.elevenlabs.audioElement.pause();
    this.isPaused = true;
  }

  resume() {
    this.providers.elevenlabs.audioElement.play();
    this.isPaused = false;
  }

  stop() {
    this.providers.elevenlabs.audioElement.pause();
    this.providers.elevenlabs.audioElement.currentTime = 0;
    this.isPaused = false;
  }

  get isPausedPlayback() {
    return this.isPaused;
  }
}

export default new TTSService();
