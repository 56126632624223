import React, { useState, useEffect } from 'react'
import { Input, Select, Switch, Button, Slider, Divider, Typography } from 'antd'
import '../styles/Settings.css'
import { getAvailableModels } from '../langchain'
import TTSService from '../services/TTSService'
import axios from 'axios'
import { message } from 'antd'
import { getModelOptions } from '../constants/models'
import { getOpenRouterModels } from '../langchain'
import { useElevenLabs } from '../contexts/ElevenLabsContext'
import { useSettings } from '../contexts/SettingsContext'
import LoadingSpinner from './LoadingSpinner'
import UserSettingsService from '../services/UserSettingsService'
import { useElevenLabsConversation } from '../hooks/useElevenLabsConversation'

const { Text } = Typography;

const API_KEY_PROVIDERS = [
  { id: 'openai', label: 'OpenAI API Key' },
  { id: 'claude', label: 'Claude API Key' },
  { id: 'gemini', label: 'Gemini API Key' },
  { id: 'mistral', label: 'Mistral API Key' },
  { id: 'openrouter', label: 'Openrouter API Key' },
  { id: 'groq', label: 'Groq API Key' },
  { id: 'ollama', label: 'Ollama API Key' },
  { id: 'elevenlabs', label: 'Elevenlabs API Key' },
  { id: 'google', label: 'Google API Key' }
];

const Settings = () => {
  const { settings, apiKeys = {}, updateSettings, updateApiKey, loading } = useSettings();
  const { updateVoice } = useElevenLabs();
  const elevenlabsConversation = useElevenLabsConversation({});

  // Keep local state for UI-specific items
  const [activeSection, setActiveSection] = useState('ai-models');
  const [isLoadingModels, setIsLoadingModels] = useState(true);
  const [modelOptions, setModelOptions] = useState({}); // Changed to object to store models by provider
  const [forceUpdate, setForceUpdate] = useState(false);

  // Load model options when AI provider changes
  useEffect(() => {
    const loadModelOptions = async () => {
      setIsLoadingModels(true);
      try {
        const models = await getAvailableModels(settings.selectedLLM, apiKeys[settings.selectedLLM], !!settings.subscription);
        setModelOptions(prev => ({...prev, [settings.selectedLLM]: models}));
      } catch (error) {
        console.error('Error loading models:', error);
        setModelOptions(prev => ({...prev, [settings.selectedLLM]: []}));
        message.error(`Failed to load models for ${settings.selectedLLM}: ${error.message}`);
      } finally {
        setIsLoadingModels(false);
      }
    };
    loadModelOptions();
  }, [settings.selectedLLM, apiKeys[settings.selectedLLM], settings.subscription]); // Only depend on the specific API key

  const handleModelChange = async (value) => {
    await updateSettings({ defaultModel: value });
  };

  const handleLLMChange = async (value) => {
    await updateSettings({ selectedLLM: value });
  };

  // Handle settings changes
  const handleConversationSettingChange = async (key, value) => {
    try {
      if (key === 'selectedVoice') {
        await elevenlabsConversation.updateVoice(value);
      }

      // Then update the settings in the database
      await updateSettings({
        conversationSettings: {
          ...settings.conversationSettings,
          [key]: value
        }
      });
    } catch (error) {
      console.error('Failed to update settings:', error);
      message.error('Failed to update voice settings. Please try again.');
    }
  };

  const handleApiKeyChange = async (llm, value) => {
    console.log('Saving API key for:', llm);
    try {
      // Save the API key
      await updateApiKey(llm, value);
      
      if (llm === 'elevenlabs') {
        console.log('Refreshing voices after ElevenLabs API key update');
        try {
          // Clear existing voices first
          TTSService.providers.elevenlabs.voices = {};
          
          // Fetch new voices with the new API key
          const voices = await TTSService.refreshVoices();
          console.log('Fetched voices after API key update:', {
            count: Object.keys(voices).length,
            voices: Object.keys(voices)
          });
          
          // Force component re-render
          setForceUpdate(prev => !prev);
          
          if (Object.keys(voices).length > 0) {
            message.success('Successfully updated ElevenLabs voices');
          } else {
            message.warning('No voices found with the provided API key');
          }
        } catch (error) {
          console.error('Failed to fetch voices:', error);
          message.error('Failed to fetch voices with the provided API key');
        }
      }
    } catch (error) {
      console.error('Failed to save API key:', error);
      message.error('Failed to save API key');
    }
  };

  const handleVoiceChange = async (value) => {
    await updateSettings({ selectedVoice: value });
  };

  const handleReadAloudChange = async (checked) => {
    await updateSettings({ readAloud: checked });
  };

  const handleTTSProviderChange = async (value) => {
    await updateSettings({ ttsProvider: value });
  };

  const getProviderOptions = () => {
    return TTSService.getAvailableProviders()
  }

  const runDatabaseTest = async () => {
    try {
      message.loading('Running database tests...');
      await UserSettingsService.testDatabaseConnection();
      message.success('Database tests completed successfully!');
    } catch (error) {
      message.error(`Database test failed: ${error.message}`);
    }
  };

  return (
    <div className='settings-container'>
      {loading || isLoadingModels ? ( // Show loading indicator while fetching models
        <LoadingSpinner />
      ) : (
        <>
          <div className='settings-sidebar'>
            <div className='settings-sidebar-header'>
              <h2>Settings</h2>
            </div>

            <div className='settings-nav'>
              <div className='settings-nav-group'>
                <div className='settings-nav-group-title'>Preferences</div>
                <div
                  className={`settings-nav-item ${
                    activeSection === 'ai-models' ? 'active' : ''
                  }`}
                  onClick={() => setActiveSection('ai-models')}
                >
                  <span>AI Models</span>
                </div>
                <div
                  className={`settings-nav-item ${
                    activeSection === 'tts' ? 'active' : ''
                  }`}
                  onClick={() => setActiveSection('tts')}
                >
                  <span>Text to Speech</span>
                </div>
                <div
                  className={`settings-nav-item ${
                    activeSection === 'conversation' ? 'active' : ''
                  }`}
                  onClick={() => setActiveSection('conversation')}
                >
                  <span>Conversation Mode</span>
                </div>
                <div
                  className={`settings-nav-item ${
                    activeSection === 'api-keys' ? 'active' : ''
                  }`}
                  onClick={() => setActiveSection('api-keys')}
                >
                  <span>API Keys</span>
                </div>
              </div>
            </div>
          </div>

          <div className='settings-content'>
            <div className='settings-content-header'>
              <div>
                <h1>
                  {activeSection === 'ai-models'
                    ? 'AI Model Settings'
                    : activeSection === 'tts'
                    ? 'Text-to-Speech Settings'
                    : 'API Configuration'}
                </h1>
                <div className='help-text'>
                  {activeSection === 'ai-models'
                    ? 'Configure your AI model preferences'
                    : activeSection === 'tts'
                    ? 'Manage text-to-speech settings'
                    : 'Manage your API keys securely'}
                </div>
              </div>
            </div>

            {activeSection === 'ai-models' && (
              <div className='settings-section'>
                <div className='settings-section-header'>
                  <div className='settings-section-title'>Model Selection</div>
                  <div className='settings-section-description'>
                    Choose your preferred AI model and provider
                  </div>
                </div>

                <div className='setting-item'>
                  <label>AI Provider</label>
                  <Select
                    value={settings?.selectedLLM || 'openai'}
                    onChange={handleLLMChange}
                    style={{ width: '100%' }}
                  >
                    <Select.Option value='openai'>OpenAI</Select.Option>
                    <Select.Option value='claude'>Claude</Select.Option>
                    <Select.Option value='gemini'>Gemini</Select.Option>
                    <Select.Option value='mistral'>Mistral</Select.Option>
                    <Select.Option value='openrouter'>OpenRouter</Select.Option>
                    <Select.Option value='groq'>Groq</Select.Option>
                    <Select.Option value='ollama'>
                      Ollama (Open Source)
                    </Select.Option>
                  </Select>
                </div>

                <div className='setting-item'>
                  <label>Model</label>
                  <Select
                    value={settings?.defaultModel}
                    onChange={handleModelChange}
                    style={{ width: '100%' }}
                    loading={isLoadingModels}
                  >
                    {modelOptions[settings.selectedLLM] && modelOptions[settings.selectedLLM].length > 0 ? (
                      modelOptions[settings.selectedLLM].map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))
                    ) : (
                      <Select.Option value="" disabled>Loading models...</Select.Option>
                    )}
                  </Select>
                </div>
              </div>
            )}

            {activeSection === 'tts' && (
              <div className='settings-section'>
                <div className='settings-section-header'>
                  <div className='settings-section-title'>Text to Speech</div>
                  <div className='settings-section-description'>
                    Configure text-to-speech preferences for message playback
                  </div>
                </div>

                <div className='read-aloud-section'>
                  <label>Enable Read Aloud</label>
                  <Switch
                    checked={settings.readAloud}
                    onChange={handleReadAloudChange}
                    checkedChildren='On'
                    unCheckedChildren='Off'
                  />
                </div>

                <div className='setting-item'>
                  <label>TTS Provider</label>
                  <Select
                    value={settings.ttsProvider}
                    onChange={handleTTSProviderChange}
                    style={{ width: '100%' }}
                  >
                    {TTSService.getAvailableProviders().map(provider => (
                      <Select.Option key={provider.value} value={provider.value}>
                        {provider.label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>

                <div className='setting-item'>
                  <label>Voice</label>
                  <Select
                    value={settings.selectedVoice}
                    onChange={handleVoiceChange}
                    style={{ width: '100%' }}
                  >
                    {TTSService.getAvailableVoices(settings.ttsProvider).map(voice => (
                      <Select.Option key={voice.value} value={voice.value}>
                        {voice.label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            )}

            {activeSection === 'conversation' && (
              <div className='settings-section'>
                <div className='settings-section-header'>
                  <div className='settings-section-title'>Voice Conversation Settings</div>
                  <div className='settings-section-description'>
                    Configure settings for two-way voice conversations with AI
                  </div>
                </div>

                <div className='setting-item'>
                  <div className='setting-row'>
                    <label>Enable Voice Conversations</label>
                    <Switch
                      checked={settings.conversationSettings.isEnabled}
                      onChange={(checked) => 
                        handleConversationSettingChange('isEnabled', checked)
                      }
                    />
                  </div>
                  <Text type="secondary" className="setting-description">
                    Enable two-way voice conversations with AI
                  </Text>
                </div>

                <div className='setting-item'>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <label>AI Voice</label>
                    <Button 
                      size="small"
                      onClick={async () => {
                        try {
                          await TTSService.refreshVoices();
                          // Force a re-render of your component
                          setForceUpdate(prev => !prev);
                        } catch (error) {
                          message.error('Failed to refresh voices');
                        }
                      }}
                    >
                      Refresh Voices
                    </Button>
                  </div>
                  <Select
                    className='voice-selector'
                    value={settings.conversationSettings.selectedVoice}
                    onChange={(value) => 
                      handleConversationSettingChange('selectedVoice', value)
                    }
                    disabled={!settings.conversationSettings.isEnabled}
                    placeholder="Select AI voice"
                  >
                    {TTSService.getAvailableVoices('elevenlabs').map(voice => (
                      <Select.Option key={voice.value} value={voice.value}>
                        {voice.label}
                      </Select.Option>
                    ))}
                  </Select>
                </div>

                <div className='setting-item'>
                  <label>Voice Stability</label>
                  <Slider
                    disabled={!settings.conversationSettings.isEnabled}
                    min={0}
                    max={1}
                    step={0.1}
                    value={settings.conversationSettings.stability}
                    onChange={(value) => 
                      handleConversationSettingChange('stability', value)
                    }
                  />
                </div>

                <div className='setting-item'>
                  <label>Clarity + Similarity Enhancement</label>
                  <Slider
                    disabled={!settings.conversationSettings.isEnabled}
                    min={0}
                    max={1}
                    step={0.1}
                    value={settings.conversationSettings.similarity}
                    onChange={(value) => 
                      handleConversationSettingChange('similarity', value)
                    }
                  />
                </div>

                <div className='setting-item'>
                  <label>Speaking Style</label>
                  <Select
                    disabled={!settings.conversationSettings.isEnabled}
                    value={settings.conversationSettings.style}
                    onChange={(value) => 
                      handleConversationSettingChange('style', value)
                    }
                    style={{ width: '100%' }}
                  >
                    <Select.Option value="balanced">Balanced</Select.Option>
                    <Select.Option value="friendly">Friendly</Select.Option>
                    <Select.Option value="professional">Professional</Select.Option>
                    <Select.Option value="casual">Casual</Select.Option>
                  </Select>
                </div>
              </div>
            )}

            {activeSection === 'api-keys' && (
              <div className='settings-section'>
                <div className='settings-section-header'>
                  <div className='settings-section-title'>API Keys</div>
                  <div className='settings-section-description'>
                    Manage your API keys for different services
                  </div>
                </div>

                <div className='setting-warning'>
                  <span>
                    Please ensure you're using a secure connection when entering API
                    keys.
                  </span>
                </div>

                {API_KEY_PROVIDERS.map(({ id, label }) => (
                  <div key={id} className='setting-item'>
                    <label>{label}</label>
                    <Input.Password
                      value={apiKeys[id] || ''}
                      onChange={e => handleApiKeyChange(id, e.target.value)}
                      placeholder={`Enter ${id} API key`}
                    />
                  </div>
                ))}
              </div>
            )}

            {/* Add test button in development environment */}
            {process.env.NODE_ENV === 'development' && (
              <div className='settings-section'>
                <div className='settings-section-header'>
                  <div className='settings-section-title'>Developer Tools</div>
                  <div className='settings-section-description'>
                    Testing utilities for development
                  </div>
                </div>
                <Button 
                  onClick={runDatabaseTest}
                  type="primary"
                  style={{ marginTop: '1rem' }}
                >
                  Test Database Connection
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default Settings
